.contact {
    overflow: hidden;
    margin-top: 2em;
}
.contact__avatar {
    width: 10rem;
    margin: 0 0 1em;
}
.contact__headline {
    @include font-minion;
    font-size: 3rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 0;
}
.contact__name,
.contact__position,
.contact__fon,
.contact__email { display: block; }

@include media-query(screen-sm) {
    .contact__avatar {
        float: left;
        width: 14rem;
        margin: 0 1em 0 0;
    }
}

.contact--call-us {
    @include font-minion;
    background-color: $valufy-green;
    font-size: 1.6rem;
    padding: 0.75rem 1.25rem;
    @include shadow;
    .contact__tagline {
        margin-right: 1rem;
    }
    // [1] Compensate border-bottom to avoid jumping on hover
    .contact__cta {
        display: inline-block;
        font-family: $base-font-sans;
        font-style: normal;
        font-weight: 700;
        padding-bottom: 1px; // [1]
        margin-left: 5px;
        &:hover {
            padding-bottom: 0;
            border-bottom: 1px solid black; // [1]
            color: black;
        }
    }
}
