@import '../../../node_modules/kirby-base-theme/src/scss/mixins/general';

@mixin font-minion {
    font-family: $base-font-serif;
    font-weight: normal;
    margin: 0;
    letter-spacing: 0em;
}

@mixin font-miso {
    font-family: $base-font-sans;
    font-style: normal;
    font-weight: normal;
    margin: 0;
    letter-spacing: 0;
    line-height: 1.1;
}

@mixin make-row($column__gutter--half: $grid__gutter--half) {
  @extend %clearfix;
  margin-left:  $column__gutter--half * -1;
}


@mixin base-font {
    @include font-miso;
    font-weight: regular;
    font-size: 1.8rem;
    line-height: 1.33;
}

@mixin shadow {
    box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.1);
}

@mixin shadow-hover {
    box-shadow: 10px 10px 30px 0 rgba(0,0,0,0.2);
}
