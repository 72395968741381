.main-teaser {
    margin: 3rem 0 2rem;
    padding: 0 0 3rem 0;
    border-bottom: 1px solid black;
    p {
        @include font-minion;
        text-transform: none;
        font-weight: normal;
        font-size: 2rem;
        line-height: 1.5;
    }
}

.sidebar-layout {
    .main-teaser {
        margin: 0 0 2rem 0;
    }
}

@include media-query(screen-sm) {
    .main-teaser p {
        @include base-font;
    }
    // .portraits {}
}
