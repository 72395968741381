@import '../../../node_modules/kirby-base-theme/src/scss/base/shared';


.icon--location,
.icon--seats,
.icon--date {
    position: relative;
    top: 1px;
}

.block {
    overflow: hidden;
}
