.page-header {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 2rem;
    .logo {
        float: left;
        width: 10rem;
    }
    .nav-toggle {
        float: right;
        top: -10px;
    }
    .main-nav { overflow: hidden; }
}

.page-header {
    .contact--call-us {
        margin: -3rem -3rem 3rem;
    }
}

@include media-query(screen-lg) {
    .page-header .logo { width: auto; }
    .page-header {
        .contact--call-us {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }
}
