.header-teasers {
    position: relative;
    //overflow: hidden;
    .teaser-box {
        margin-top: 2rem;
    }
}
.teaser-box {
    background-color: $valufy-green;
    // margin-top: 2rem;
    padding: 2rem;
    @include shadow;
}
.teaser-box__headline {
    @include font-minion;
    font-weight: 700;
    font-size: 2.6rem;
    margin: 0 0 1rem;
}
.teaser-box__body {
    @include base-font;
    p { margin: 0 0 1.5rem; }
}
.teaser-box--solid {
    background: $valufy-green;
}

.teaser-box--applegreen {
    background: $color-applegreen;
}

.teaser-box--aquamarine {
    background: $color-aquamarine;
}

.teaser-box--bluegreen {
    background: $color-bluegreen;
}

@include media-query(screen-sm) {
    .header-teasers {
        margin-left: -2rem;
        margin-top: -8rem;
        .flexbox & {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }
    .header-teasers .teaser-box {
        width: 80%;
        margin: 2rem auto 0;
    }
}


@include media-query(screen-md) {
    .header-teasers .teaser-box {
        width: calc(33.333% - 2rem);
        margin: 0 0 0 2rem;
        .no-flexbox & { float: left; }
    }
}
