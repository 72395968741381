// @import '../../../node_modules/kirby-base-theme/src/scss/components/navs';

nav {
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
        &::before { content: none; }
    }
}

// RESPONSIVE-NAV STYLES
.nav-collapse {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
    list-style: none;
    li {
        display: block;
        width: 100%;
    }
}
.js .nav-collapse {
    clip: rect(0 0 0 0);
    max-height: 0;
    position: absolute;
    display: block;
    overflow: hidden;
    zoom: 1;
}
.nav-collapse.opened { max-height: 9999px; }
.nav-toggle {
    -webkit-tap-highlight-coslor: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

// MAIN NAVIGATION STYLES
.nav--main {
    margin-top: 2rem;
    li {
        @include font-minion;
        margin: 0;
        padding: 0;
        line-height: 1.5;
    }
}

.nav--secondary {
    font-size: 1.3rem;
    line-height: 1.5;
}


@media screen and (min-width: 61em) {
    .js .nav-collapse {
        position: static;
        width: auto;
    }
    .js .nav-collapse.closed { max-height: none; }
    .nav-toggle { display: none; }
    .nav--main {
        margin-top: 3.5rem;
        ul {
            display: flex;
            justify-content: flex-end;
            li {
                width: auto;
                margin-left: 2.5rem;
                font-size: 1.8rem;
                .no-flexbox & { float: left; }
            }
        }
    }
}
