.instructor {}

.instructor-widget {
    padding: 2rem;
    background-color: $color-primary;
    @include shadow;

    ._headline {
        display: block;
        margin: 0 0 $grid__gutter;
        font-family: $base-font-serif;
        font-size: 2.6rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;
        text-transform: none;
        hyphens: none;
    }
    ._image {
        width: 8rem;
        height: 8rem;
        overflow: hidden;
        margin-top: 0;
        margin-bottom: 3rem;
        border-radius: 100%;
    }
    ._text {
        font-size: 1.6rem;
        line-height: 1.5;
    }
    ._text h3 {
        font-family: $base-font-serif;
        font-size: 2.2rem;
        font-weight: bold;
        font-style: italic;
        line-height: 2.4rem;
        text-transform: none;
        letter-spacing: 0;
        margin: 0;
    }
    ._text p:last-child {
        margin-bottom: 0;
    }
    ._text a {
        text-decoration: underline;
        &:hover {
            color: black;
            text-decoration: none;
        }
    }
}

.instructor-placeholder {
    width: 15.6rem;
    height: 23.5rem;
    background: linear-gradient(to left, $valufy-green 0%, $valufy-darkgreen 100%);
}

.instructor--bio {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    overflow: hidden;
    border-bottom: 2px solid $valufy-green;
    .instructor__image {
        flex-shrink: 0;
        margin: 0 2rem 2rem 0;
    }
    .instructor__text  h3 { margin: 0; }
}

@include media-query(screen-sm) {
    .instructor--bio {
        flex-direction: row;
        .instructor__image {
            padding-bottom: 0;
            .no-flexbox & { float: left; }
        }
    }
}

@include media-query(screen-md) {
    .instructor-widget {
        flex-direction: column;
    }
}
