
.page-signup {
    .seminar-price { color: $valufy-green; }
    .sidebar h2 {
        @include font-minion;
        font-weight: bold;
        margin-top: 1em;
        font-size: 2.4rem;
        font-style: normal;
        line-height: 1.3;
    }
}
