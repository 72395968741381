// No Flexbox support fallback for instructors swiper on front page
.no-flexbox .instructors {

    @extend %clearfix;
    margin-left: -#{$grid__gutter};

    .swiper-slide {
        float: left;
        margin-left: $grid__gutter;
        margin-bottom: $grid__gutter;
    }
}
