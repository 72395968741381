.page-footer {
    background-color: $color-gray-light;
}

.page-footer .container {
    @extend %clearfix;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.page-footer {
    font-size: 1.3rem;
    line-height: 1.5;
    font-style: normal;
    strong { 
        @include font-miso;
        font-weight: bold;
        font-size: 1.8rem;
    }
    .legal-nav {
        margin-top: 1rem;
    }
    .mission-statement, .nav--secondary, .legal  {
        @include font-minion;
    }
    .nav--secondary {
        margin-bottom: 1rem;
    }

    .legal {
        color: $color-gray;
        text-decoration: underline;
    }
}
