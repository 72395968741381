.card {
    padding: 0 2rem 2rem;
    background: $color-gray-light;
    @include shadow;
    overflow: visible !important;
    transition: all .3s ease;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    // TODO: this should not be here
    .seminar__image {
        margin: 0 -2rem;
    }
    // TODO: this should not be here
    .seminar__tag {
        margin-top: -1.8rem;
    }
}
.no-touchevents {
    .card:hover {
        @include shadow-hover;
        transform: translate(-1px, -2px) scale(1.01);
        z-index: 1001;
    }
}

