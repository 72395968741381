.credits-tag {
    width: 100%;
    background-color: $valufy-green;
    font-size: 2.2rem;
    line-height: 2;
    text-align: center;
    strong {
        font-family: "misobold", sans-serif;
        font-style: normal;
        font-weight: normal;
        letter-spacing: .1em;
    }
    span {}
}

@include media-query(screen-md) {
    .credits-tag {
        width: 14rem;
        height: 14rem;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        right: 2em;
        bottom: 1em;
        line-height: 1;
        transform: rotateZ(-10deg);
        strong {
            display: block;
            font-size: 3rem;
            margin-top: 1em;
        }
        span {
            display: block;
            padding: 0 1em;
        }
    }
}
