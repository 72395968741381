.seminar {}


.seminar--bold {
    position: relative;
    overflow: hidden;
    .seminar__tag {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.8rem;
    }
    .seminar__image {
        margin-top: 2rem;
        height: 12rem;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .seminar__title {
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-family: $base-font-serif;
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0;
        text-transform: none;
        padding-bottom: 1rem;
        border-bottom: 2px solid $valufy-green;

        a:hover {
            color: black;
        }

    }
    .seminar__teaser {
        @include base-font;
        margin-bottom: 1.5rem;
    }


    &.seminar--applegreen {
        .seminar__title {
            border-bottom: 2px solid $color-applegreen;
        }
        .tag, .btn {
            background-color: $color-applegreen;
        }
        .btn:hover {
            background-color: lighten($color-applegreen, 25%);
        }
    }
    &.seminar--aquamarine {
        .seminar__title {
            border-bottom: 2px solid $color-aquamarine;
        }
        .tag, .btn {
            background-color: $color-aquamarine;
        }
        .btn:hover {
            background-color: lighten($color-aquamarine, 25%);
        }
    }
    &.seminar--bluegreen {
        .seminar__title {
            border-bottom: 2px solid $color-bluegreen;
        }
        .tag, .btn {
            background-color: $color-bluegreen;
        }
        .btn:hover {
            background-color: lighten($color-bluegreen, 25%);
        }
    }


    .seminar__details {
        margin-bottom: 0;
        padding-top: 0.5rem;
        border-top: 0px solid $color-applegreen;
        line-height: 1.5;
    }
    .seminar__detail {
        display: inline-block;
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
        &:not(:last-child) {
            margin-right: 1.5rem;
        }
        img {
            display: inline-block;
            margin-right: 1rem;
        }
    }

    @include media-query(screen-sm) {
        // [1] forces the block onto the next line
        .seminar__price {
            display: block; // [1]
        }
    }
}

.seminar__description,
.seminar__contact {
    padding-bottom: 4rem;
    border-bottom: 2px solid $valufy-green;
}

@include media-query(screen-md) {
    .seminar__contact > .contact {
         margin-left: 33.334%;
    }
    .seminar__title,
    .seminar__details {
        min-height: 6rem;
    }
}
