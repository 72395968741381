.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0;
    input {
        display: none;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid $color-primary;
        background-color: transparent;;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
        &::before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 3px;
            top: 3px;
            bottom: 4px;
            -webkit-transition: .4s;
            transition: .4s;
            background-color: $color-primary;
            border-radius: 50%;
        }
    }

    input:checked + .slider {
        background-color: $color-primary;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
        background-color: white;
    }
}

.switch__text {
    display: inline-block;
    color: $color-text;
    font-size: inherit;
}

