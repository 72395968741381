.contact-widget {
    padding: $grid__gutter;
    background-color: $color-gray-light;
    @include shadow;
    overflow: hidden;
    ._headline {
        display: block;
        margin: 0 0 $grid__gutter;
        font-family: $base-font-serif;
        font-size: 2.6rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;
        text-transform: none;
        hyphens: none;
    }
    ._avatar {
        width: 8rem;
        height: 8rem;
        margin: 0 0 $grid__gutter;
        overflow: hidden;
        border-radius: 100%;
    }
    ._text {
        font-size: 1.6rem;
        line-height: 1.5;
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
                color: currentColor;
            }
        }
    }
    ._name,
    ._position,
    ._fon,
    ._email { display: block; }
    @include media-query(screen-sm) {
        ._avatar {

        }
    }
}
