
@font-face {
    font-family: 'misobold';
    src: url('../fonts/miso-bold-webfont.woff2') format('woff2'),
    url('../fonts/miso-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* barlow-regular - latin */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    src: local('Barlow Regular'), local('Barlow-Regular'),
         url('../fonts/barlow-v3-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/barlow-v3-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* barlow-700 - latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    src: local('Barlow Bold'), local('Barlow-Bold'),
         url('../fonts/barlow-v3-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/barlow-v3-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }