// @import '../../../node_modules/kirby-base-theme/src/scss/components/forms';

form {
    .form-element {
        position: relative;
        margin-bottom: 1em;
    }
    .form-element > label {
        display: inline-block;
    }
    .input-placeholder {
        color: $valufy-green;
    }
    //::-webkit-input-placeholder {@extend .input-placeholder;}
    //:-ms-input-placeholder {@extend .input-placeholder;}
    //::-moz-placeholder {@extend .input-placeholder;}

    a {
        position: relative;
        &::after {
            @extend %anchorPseudoAfter
        }
        &:hover {
            color: $color-text;
            &::after {
                visibility: hidden;
            }
        }
    }
}

.form-element--checkbox {
    input:checked + .check::after {
        opacity: 1;
    }
    .check {
        float: left;
        width: 2rem;
        height: 2rem;
        margin: 5px 0.75rem 0 0;
        position: relative;
        color: $valufy-green;
        &::before {
            content: '';
            display: block;
            width: 2rem;
            height: 2rem;
            border: 2px solid currentColor;
            visibility: visible;
        }
        &::after {
            content: '';
            width: 1.25rem;
            height: 0.5rem;
            position: absolute;
            top: 0.7rem;
            left: 0.33rem;
            border: 2px solid currentColor;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
            transition: opacity .3s ease;
            visibility: visible;
        }
    }

    input {
        visibility: hidden;
        position: absolute;
    }
}

.form-inline {
    // only hide labels if placeholder feature is supported
    .placeholder & .form-element:not(.form-element--checkbox) {
        label { @extend .sr-only; }
    }
}
.form-errors {
    margin-bottom: 1em;
    padding: 1em;
    background-color: $valufy-green;
    color: white;
    p { margin: 0; }
    a {
        color: black;
        &:hover,
        :focus {
            text-decoration: underline;
        }
    }
}
@media screen and (min-width: 34.375em) {
    .form-element-wrapper {
        .flexbox & {
            display: flex;
            .half { flex-basis: 50%; }
            .one-third { flex-basis: 33%; }
            .two-thirds { flex-basis: 67%; }
            .half:not(:first-child),
            .one-third:not(:first-child),
            .two-thirds:not(:first-child) { margin-left: 1em; }
        }
    }
}
input[type*="text"],
input[type*="search"],
input[type*="number"],
input[type*="email"],
textarea,
select {
    width: 100%;
    padding: .5em;
    border: 2px solid $valufy-green;
    transition: padding .3s ease;
}
input[type*="text"],
input[type*="search"],
input[type*="number"],
input[type*="email"],
textarea,
select {
    &:focus,
    &:active {
        padding-top: 0.5em;
        // padding-bottom: calc(.5em - 3px); // shrink padding by the additional border size
        outline: 0;
        border-bottom: 6px solid $valufy-green;
        .placeholder & + label {
            width: auto;
            height: auto;
            font-size: .8em;
            color: $valufy-green;
            left: .9em;
            top: .4em;
            @include font-miso();
        }
    }
    &.is-error { border-bottom-color: black; }
}

.signup-form { margin-top: 1.5em; }
