@include media-query(screen-sm) {

    .analytics-optout {
        display: flex;
        .switch {
            flex: 0 0 auto;
            margin-right: 1rem;
        }
    }

}
