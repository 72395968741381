.flexbox {
    .swiper-pagination {
        position: static;
        margin-top: .5em;
        text-align: center;
    }
    .swiper-pagination-bullet {
        width: .5em;
        height: .5em;
        background-color: transparent;
        border: 1px solid black;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background-color: $valufy-green;
        border: 0;
    }
}
