// compensates negative margin of .seminar__tag
.seminar-list {
    padding-top: 2rem; // [1]
}

.seminar-list__item {
    padding-bottom: 3rem;
    background-color: white;
    h3 { margin: 0 0 3rem 0; }
}

.seminar-list__filter { margin-bottom: 1em;   }

.seminar-list--bold {
    .seminar-list__item {}
}

@include media-query(screen-sm) {

    .seminar-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-left: -2rem;
    }
    .seminar-list__item {
        width: calc(50% - 2rem);
        margin-left: 2rem;
    }
    .seminar-list__filter {
        ul { display: flex; }
        li:not(:last-child) { margin-right: .5em;}
    }

}

@include media-query(screen-md) {
    .seminar-list__item {
        width: calc(33.333% - 2rem);
        min-height: 20rem;
        margin-left: 2rem;
        .no-flexbox & { float: left; }
    }
}
