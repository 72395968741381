.seminar-header {
    position: relative;
}



.page-seminar {
    .cover-image {
        margin-bottom: 0;
    }
}

// [1] Neccessary to align price and dates on baseline
.seminar-meta {
    padding: 3rem $grid__gutter $grid__gutter;
    background-color: $color-gray-light;
    @include shadow;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 2rem;

    ._title {
        width: 60%;
        margin: 1rem 0;
        //padding-bottom: 2rem;
        font-family: $base-font-serif;
        font-weight: bold;
        text-transform: none;
        letter-spacing: 0;
        //border-bottom: 2px solid $color-primary;
    }
    ._tag {
        // margin-top: -4rem;
        // margin-bottom: 2rem;
        margin: 0;
        position: absolute;
        top: -$grid__gutter;
        left: 0;
    }
    ._dates {
        font-size: 1.8rem;
        line-height: 1.3;
        margin-bottom: 0;
        span {
            display: inline-block;
            margin-right: 2rem;
        }
        img {
            display: inline-block;
            margin-right: 1rem;
        }
    }
    ._price {
        display: inline-block;
        margin-bottom: 0;
        font-size: 1.8rem;
        line-height: 2; // [1]
        strong {
            font-family: $base-font-serif;
            font-size: 2.6rem;
            padding-right: 0.5rem;
        }
    }
    hr {
        width: 100%;
        height: 2px;
        color: $color-primary;
        background-color: $color-primary;
        margin: 0.3rem 0 3rem 0;
        border: 0;
    }

    &.seminar-meta__applegreen {
        ._tag, .btn {
            background-color: $color-applegreen;
        }
        .btn:hover {
            background-color: lighten( $color-applegreen, 25%);
        }
        .btn--inverted {
            background-color: white;
            &:hover {
                background-color: lighten( $color-applegreen, 25%);
            }
        }
        hr {
            color: $color-applegreen;
            background-color: $color-applegreen;
        }
    }
    &.seminar-meta__aquamarine {
        ._tag, .btn {
            background-color: $color-aquamarine;
        }
        .btn:hover {
            background-color: lighten( $color-aquamarine, 25%);
        }
        .btn--inverted {
            background-color: white;
            &:hover {
                background-color: lighten( $color-aquamarine, 25%);
            }
        }
        hr {
            color: $color-aquamarine;
            background-color: $color-aquamarine;
        }
    }
    &.seminar-meta__bluegreen {
        ._tag, .btn {
            background-color: $color-bluegreen;
        }
        .btn:hover {
            background-color: lighten( $color-bluegreen, 25%);
        }
        .btn--inverted {
            background-color: white;
            &:hover {
                background-color: lighten( $color-bluegreen, 25%);
            }
        }
        hr {
            color: $color-bluegreen;
            background-color: $color-bluegreen;
        }
    }
}





@include media-query(screen-md-max) {
    .seminar-meta {
        ._title {
            font-size: 3rem;
            width: 100%;
        }
    }
}


@include media-query(screen-lg) {

    .seminar-actions {
        //width: calc( 33.4% - #{ 2 * $grid__gutter} );
        position: absolute;
        bottom: $grid__gutter;
        right: $grid__gutter;
        text-align: center;
    }
}


@include media-query(screen-sm) {
    .seminar-actions {

        .btn {
            display: inline-block;
        }
        .btn:not(:last-child) {
            margin-bottom: 0;
            margin-right: $grid__gutter;
        }
        
    }
}