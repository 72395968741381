$color-applegreen: #D1D93F;
$color-aquamarine: #58bdb4;
$color-bluegreen: #299795;

$valufy-green: $color-applegreen;
$valufy-darkgreen: #889E4A;
$color-gray-light: rgb(245, 245, 245);
$color-gray: rgb(155, 155, 155);

$base-font-serif: "minion-pro", serif;
$base-font-sans: "Barlow", sans-serif;

// use susy utilities first
@import '../../node_modules/susy/sass/susy-prefix';

@import "normalize";
@import "variables";

@import 'mixins/general';
@import 'mixins/responsive';

.flexbox {
    @import "../../node_modules/swiper/dist/css/swiper";
}

@import "base/base";
@import "base/fonts";
@import "base/grid";
@import "base/layout";
@import "base/shared";
@import "base/typography";

@import "components/buttons";
@import "components/forms";
@import "components/lists";

@import "components/card";
@import "components/credits-tag";
@import "components/contact";
@import "components/contact-widget";
@import "components/cover-image";
@import "components/feature-box";
@import "components/instructor";
@import "components/hamburger";
@import "components/main-teaser";
@import "components/navs";
@import "components/page-footer";
@import "components/page-header";
@import "components/seminar";
@import "components/seminar-list";
@import "components/seminar-header";
@import "components/swiper";
@import "components/tag";
@import "components/teaser-box";
@import "components/testimonials";
@import "components/switch";
@import "components/analytics-optout";

@import "pages/frontpage";
@import "pages/seminars";
@import "pages/seminar";
@import "pages/signup";
@import "pages/landing-page";

@import "utilities/utilities";
