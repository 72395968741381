.landing-page {
    // [1] overlap content and cover image
    .cover-image {
        position: static; // [1]
    }
     & > .container {
         margin-top: -1.5rem; // [1]
     }
    .main-teaser {
        margin-top: 0;
        border-bottom: 0;
        h1 {
            font-family: $base-font-serif;
            font-weight: bold;
            letter-spacing: 0;
            text-transform: none;
            line-height: 1.125;
            margin-bottom: 1rem;
        }
        p {
            font-size: 2rem;
            line-height: 1.5;
            margin-bottom: 1.5rem;
        }
    }
    .contact {
        margin-top: 0;
    }
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        font-family: $base-font-serif;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0;
        text-transform: none;
    }
}


.landing-page--datenanalyse {
    .main-teaser h1 {
        max-width: 65rem;
    }
    .section--intro {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .section--cta { margin-bottom: 8rem; }
    .section--features { margin-bottom: 4rem; }
    .section--seminars {
        box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
        h2 {
            margin-bottom: 4rem;
        }
        div[class*="col-"] { margin-bottom: 4rem; }
    }
    .section--testimonials { margin-bottom: 4rem; }
    .feature-box__title {
        display: block;
        max-width: 80%;
    }
    .testimonial {
        margin-bottom: 4rem;
    }
}

@include media-query(screen-md) {
    .landing-page--datenanalyse {
        .teaser-box--inhouse {
            height: 39rem;
        }
        .testimonial {
            margin-bottom: 0;
        }
    }
}
