@import '../../../node_modules/kirby-base-theme/src/scss/components/lists';


.list-nostyle {
    padding: 0;
    li {
        padding-left: 0;
        &::before {
            content: none;
        }
    }
}
