.page-seminar {
    .seminar-header {
        margin-bottom: $grid__gutter;
    }
    .sidebar .widget {
        max-width: 40rem;
        margin: 0 0 2rem;
    }
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        font-family: $base-font-serif;
        letter-spacing: 0;
        text-transform: none;
    }

    h1, .h1 {
        font-size: 3.2rem;
        line-height: 1.25;
    }

    h2, .h2 {
        border-top: 1px solid black;
        padding: 2rem 0;
        margin-top: 4rem;
    }

    .seminar-header {
        .seminar-actions {
            .btn {
                width: 100%;
                text-align: center;
            }
        }
    }

    @include media-query(screen-sm) {
        .seminar-header .seminar-actions {
            .btn {
                width: auto;
                text-align: center;
            }
        }
    }

    @include media-query(screen-sm-max) {
        .cover-image {
            margin: 0 -3rem;
        }
    }

    @include media-query(screen-md-max) {
        .seminar-header .seminar-actions {
            .btn:not(:last-child) {
                margin-right: 1.5rem;
            }
        }
        @media screen and (max-height: 570px) {
            .cover-image {
                display: none;
            }
        }
    }

}
