// STICKY FOOTER
.wrapper {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
}
main {
    flex-grow: 1;
    margin-bottom: 4rem;
}

.sidebar-layout {
    display: flex;
    flex-flow: column wrap;
}


@include media-query(screen-md) {
    .sidebar-layout {
        .cover-image {
            margin-bottom: 3rem;
        }

        flex-flow: row wrap;
        .content {
            width: 66.667%;
            order: 2;
        }
        .sidebar {
            width: calc(33.333% - #{$grid__gutter});
            margin-right: $grid__gutter;
            order: 1;
        }
    }
    .sidebar-layout--reverse {
        flex-direction: row-reverse;
        .sidebar {
            margin-left: $grid__gutter;
            margin-right: 0;
        }
    }
}
