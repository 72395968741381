// @import '../../../node_modules/kirby-base-theme/src/scss/base/base';


::-moz-selection {
    background-color: $valufy-green;
    color: black;
}
::selection {
    background-color: $valufy-green;
    color: black;
}

*,
*:before,
*:after { box-sizing: border-box; }
html {
    font-size: 62.5%;
    -webkit-font-variant-ligatures: no-common-ligatures;
    hyphens: auto;
}
body {
    font-family: $base-font-sans;
    // font-style: italic;
    font-weight: normal;
    color: black;
    font-size: 1.8rem;
    line-height: 1.4;
    overflow-x: hidden;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    hyphens: none;
}

// Headlines
h1, .h1,
h3, .h3 {
    @include font-minion;
    font-weight: bold;
    margin-top: 3rem;
    margin-bottom: 2rem;
}
h2, .h2 {
    @include font-minion;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 3.6rem;
}
h1, .h1 { margin: 0; }

h4 { margin-bottom: 0; }


// MISC
a {
    text-decoration: none;
    color: black;
    &:hover,
    &.active { color: $valufy-green; }
}
img {
    display: block;
    max-width: 100%;
    height: auto;
}
figure {
    margin: 0;
}

ul,
ol,
p {
    margin-top: 0;
    margin-bottom: 2rem;
}
ul {
    padding: 0;
    list-style: none;
}
li {
    padding-left: 2.7rem;
    padding-bottom: 0.5rem;
    &::before {
        content: '';
        float: left;
        width: .8rem;
        height: .8rem;
        margin: 0 1rem 0 -2.5rem;
        position: relative;
        top: 0.8rem;
        background-color: #D1D93F;
    }
}
p + ul {
    margin-top: -1em;
}
