@import '../../node_modules/kirby-base-theme/src/scss/variables';

$wrapper-max:           120rem;
$grid__gutter:          2rem;
$grid__columns:         12;
$susy: (
    'columns':          susy-repeat($grid__columns),
    'gutters':          $grid__gutter,
    'spread':           narrow,
    'container-spread': wide
);

$breakpoints: (
  xs: (
    min:        0,
    max:        (40em - 0.0625em), // 639px
  ),
  sm: (
    min:        40em, // 640px
    max:        (48em - 0.0625em), // 767px
  ),
  md: (
    min:        48em, // 768px
    max:        (55em - 0.0625em), // 879px
  ),
  lg: (
    min:        55em, // 880px
    max:        (68.75em - 0.0625em), // 1099px
  ),
  xl: (
    min:        68.75em, // 1100px
    max:        (80em - 0.0625em), // 1279px
  ),
  xxl: (
    min:        80em, // 1280x
    max:        "none"
  ),
);

$additional_breakpoints: (
  navcollapse: (
    min:        55em, // 768px
    max:        (48em - 0.0625em), // 767px
  )
);

$color-primary: $valufy-green;
