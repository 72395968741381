%anchorPseudoAfter {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: currentColor;
  bottom: -0.2rem;
  left: 0;
}

.text a {
  position: relative;
  &::after {
    @extend %anchorPseudoAfter
  }
  &:hover {
    color: $color-text;
    &::after {
      visibility: hidden;
    }
  }
}