// @import '../../../node_modules/kirby-base-theme/src/scss/components/buttons';

.btn {
    @include font-miso;
    display: inline-block;
    padding: 1rem;
    font-size: 1.8rem;
    font-weight: 700;
    background-color: $valufy-green;
    border: 0;
    // border-bottom: 2px solid black;
    user-select: none;
    transition: background 0.2s ease-in-out;
    &:hover {
        background-color: lighten( $valufy-green, 25%);
        color: black;
    }
    &.active {
        background-color: lighten( $valufy-green, 25%);
        color: black;
    }
}
.btn--block {
    display: block;
    text-align: center;
}
.btn--inverted {
    background-color: lighten( $valufy-green, 25%);
    &:hover {
        background-color: white;
    }
    &.active {
        background-color: white;
    }
}
.btn--white {
    background-color: white;
    &:hover,
    &.active {
        background-color: rgba(255, 255, 255, 0.8);
    }
}
.btn[disabled] {
    background-color: $valufy-darkgreen;
    color: white;
}

.button-group {
    .btn:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}
