.testimonial {
    position: relative;
}
.testimonial__name {
    display: block;
    @include font-miso;
}
.testimonial__company {
    font-size: 1.6rem;
    font-weight: bold;
}
.testimonial__quote {
    margin: 2rem 0 0;
    padding: 2rem 0;
    position: relative;

    &::before,
    &::after {
        color: $valufy-green;
        font-family: $base-font-serif;
        font-size: 4rem;
        font-weight: bold;
        display: block;
        position: absolute;
    }
    &::before {
        content: '„';
        top: -4rem;
    }
    // [1] align quotation mark with visible edge
    &::after {
        content: '“';
        left: -0.5rem; // [1]
        bottom: -2rem;
    }
}
